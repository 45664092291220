.App {
  text-align: left;
  margin-bottom: 1em;
}

body {
  background-color: #343434;
  color: #F0F0F0;
}

.input-field label {
  color: #F0F0F0;
}

.input-field input[type=text] {
  color: #BEBEBE;
}

.input-field input[type=password] {
  color: #BEBEBE;
}

.submit-button {
  text-align: center;
}

textarea {
  color: #BEBEBE;
}

@media screen and (min-width: 800px) {
  .branding {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    margin-top: 0.5em;
  }

  .ad-settings-container {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: row;
  }
  
  .half-screen {
    width: 50%;
    vertical-align: top;
  }
}

@media screen and (max-width: 799px) {
  .branding {
    display: block;
    align-items: center;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    margin-top: 0.5em;
  }

  .ad-settings-container {
    width: 100%;
    margin-left: 10%;
    margin-right: 10%;
    display: block;
    flex-direction: row;
  }
  
  .half-screen {
    width: 80%;
    vertical-align: top;
  }
}

  .branding > img {
    margin-right: 1em;
    width: 100px;
    height: 100px;
  }

.ad-vendor-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  display: block;
  flex-direction: row;
}

.input-columns {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 0.3em;
}

.single-column-centered {
  text-align: center;
}

.input-label {
  width: 40%;
  vertical-align: center;
  text-align: left;
  font-size: medium;
}

.input-box {
  width: 55%;
  vertical-align: center;
  text-align: left;
  font-size: medium;
}

.span-error {
  display: block;
  margin-top: 1em;
  color: #F44336;
}

.span-error-right {
  display: block;
  margin-left: 1em;
  color: #F44336;
}

.legend-tooltip {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.tooltip-help-icon {
  width: 25px;
  height: 25px;
  display: block;
  margin-left: 5px;
  margin-right: 5px;
}

.tooltip-icon .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  padding: 15px;
  position: absolute;
  z-index: 2;
}

.tooltip-icon:hover .tooltiptext {
  visibility: visible;
}

.ad-preview-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.ad-preview-box {
  display: flex;
  flex-direction: row;
  height: 100px;
  font-size: medium;
  line-height: normal;
}

.ad-content-preview {
  text-align: center;
  width: auto;
  float: right;
  flex-grow: 1;
}

.ad-preview-title {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.ad-preview-content {
  display: block;
  font-size: 1em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.submission {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}